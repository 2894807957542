import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SubmitTraining from './SubmitTraining';

const TrainingManagement = ({ employee, isEditable }) => {
    const [trainings, setTrainings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (employee && employee.id) {
            fetchTrainings(employee.id);
        }
    }, [employee]);

    const fetchTrainings = async (employeeId) => {
        try {
            setLoading(true);
            const response = await api.get(`/api/training/employee/${employeeId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setTrainings(response.data);
        } catch (err) {
            console.error("Error fetching training data:", err);
            setError(err.response?.data?.error || err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = () => {
        if (employee && employee.id) {
            fetchTrainings(employee.id);
        }
    };

    const handleNewTraining = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSubmitSuccess = () => {
        handleCloseDialog();
        handleRefresh();
    };

    if (!employee) return <Typography>No employee selected.</Typography>;
    if (loading) return <Typography>Loading training records...</Typography>;
    if (error) return <Typography>Error: {error}</Typography>;

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: 2 }}>
                <Button onClick={handleNewTraining} variant="contained" color="primary" sx={{ mr: 2 }}>
                    NEW TRAINING
                </Button>
                <Button onClick={handleRefresh} variant="outlined">
                    REFRESH TRAINING DATA
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Training Name</TableCell>
                            <TableCell>Date Completed</TableCell>
                            <TableCell>Expiry Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trainings.map((training) => (
                            <TableRow key={training.id}>
                                <TableCell>{training.training_name}</TableCell>
                                <TableCell>{new Date(training.date_completed).toLocaleDateString()}</TableCell>
                                <TableCell>{training.expiry_date ? new Date(training.expiry_date).toLocaleDateString() : 'N/A'}</TableCell>
                                <TableCell>{training.description}</TableCell>
                                <TableCell>
                                    <Button variant="outlined" sx={{ mr: 1 }}>VIEW</Button>
                                    <Button variant="outlined">EDIT</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>New Training</DialogTitle>
                <DialogContent>
                    <SubmitTraining 
                        employeeId={employee.id} 
                        onSubmitSuccess={handleSubmitSuccess}
                        isManager={true} // Adjust this based on your user roles logic
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TrainingManagement;