import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Layout from './Layout';
import ProtectedRoute from './ProtectedRoute';
import ErrorBoundary from './ErrorBoundary';

// Public routes
import LandingPage from './LandingPage';
import Login from './Login';
import Register from './Register';
import PasswordRecovery from './PasswordRecovery';
import PasswordReset from './PasswordReset';
import RegistrationSuccess from './RegistrationSuccess';

// Protected routes
import Dashboard from './Dashboard';
import AdminDashboard from './AdminDashboard';
import OwnerDashboard from './OwnerDashboard';
import ManagerDashboard from './ManagerDashboard';
import UserDashboard from './UserDashboard';
import EmployeeCard from './EmployeeCard';
import EmployeeReviews from './EmployeeReviews';
import HolidayCalendar from './HolidayCalendar';
import SubmitHolidayRequest from './SubmitHolidayRequest';
import SubmitAbsence from './SubmitAbsence';
import HolidayApproval from './HolidayApproval';
import SupplierCard from './SupplierCard';
import IncomeCard from './IncomeCard';
import Notes from './Notes';
import PersonalInfo from './PersonalInfo';
import SubmitTraining from './SubmitTraining';
import Disciplinary from './Disciplinary';
import SpaceRentalCard from './SpaceRentalCard';
import EmploymentApplications from './EmploymentApplications';
import DocumentApproval from './DocumentApproval';
import Reminders from './Reminders';
import SubmitEmployeeReview from './SubmitEmployeeReview';
import Unauthorized from './Unauthorized';

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/password-recovery" element={<PasswordRecovery />} />
            <Route path="/password-reset/:token" element={<PasswordReset />} />
            <Route path="/registration-success" element={<RegistrationSuccess />} />

            {/* Protected routes */}
            <Route element={<Layout />}>
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/admin_dashboard" element={<ProtectedRoute allowedRoles={['ADMIN']}><AdminDashboard /></ProtectedRoute>} />
              <Route path="/owner_dashboard" element={<ProtectedRoute allowedRoles={['ACCOUNT_OWNER']}><OwnerDashboard /></ProtectedRoute>} />
              <Route path="/manager_dashboard" element={<ProtectedRoute allowedRoles={['ACCOUNT_MANAGER']}><ManagerDashboard /></ProtectedRoute>} />
              <Route path="/user_dashboard" element={<ProtectedRoute allowedRoles={['ACCOUNT_USER']}><UserDashboard /></ProtectedRoute>} />
              <Route path="/employee_card" element={<ProtectedRoute><EmployeeCard /></ProtectedRoute>} />
              <Route path="/employee_reviews" element={<ProtectedRoute><EmployeeReviews /></ProtectedRoute>} />
              <Route path="/holiday_calendar" element={<ProtectedRoute><HolidayCalendar /></ProtectedRoute>} />
              <Route path="/submit_holiday_request" element={<ProtectedRoute><SubmitHolidayRequest /></ProtectedRoute>} />
              <Route path="/submit_absence" element={<ProtectedRoute><SubmitAbsence /></ProtectedRoute>} />
              <Route path="/holiday_approval" element={<ProtectedRoute><HolidayApproval /></ProtectedRoute>} />
              <Route path="/supplier_card" element={<ProtectedRoute><SupplierCard /></ProtectedRoute>} />
              <Route path="/income_card" element={<ProtectedRoute><IncomeCard /></ProtectedRoute>} />
              <Route path="/notes" element={<ProtectedRoute><Notes /></ProtectedRoute>} />
              <Route path="/reminders" element={<ProtectedRoute><Reminders /></ProtectedRoute>} />
              <Route path="/personal_info" element={<ProtectedRoute><PersonalInfo /></ProtectedRoute>} />
              <Route path="/submit_training" element={<ProtectedRoute><SubmitTraining /></ProtectedRoute>} />
              <Route path="/disciplinary" element={<ProtectedRoute><Disciplinary /></ProtectedRoute>} />
              <Route path="/space_rental_card" element={<ProtectedRoute><SpaceRentalCard /></ProtectedRoute>} />
              <Route path="/submit_employee_review" element={<ProtectedRoute><SubmitEmployeeReview /></ProtectedRoute>} />
              <Route path="/employment_applications" element={<ProtectedRoute><EmploymentApplications /></ProtectedRoute>} />
              <Route path="/document_approval" element={<ProtectedRoute><DocumentApproval /></ProtectedRoute>} />
            </Route>

            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;