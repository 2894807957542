import React, { createContext, useContext, useState, useEffect } from 'react';
import api from './axiosConfig';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    // Check for existing token on component mount
    const token = localStorage.getItem('authToken');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      // You might want to validate the token here
    }
  }, []);

  const login = async (email, password) => {
    setIsLoggingIn(true);
    try {
      const response = await api.post('/auth/login', { email, password });
      console.log('Login response:', response.data);
      
      if (response.data.success) {
        setUser(response.data.user);
        localStorage.setItem('authToken', response.data.access_token);
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
        console.log('User set:', response.data.user);
        setIsLoggingIn(false);
        return response.data;
      } else {
        console.log('Login failed:', response.data.error);
        setIsLoggingIn(false);
        return null;
      }
    } catch (error) {
      console.error('Login error:', error);
      setIsLoggingIn(false);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('authToken');
    delete api.defaults.headers.common['Authorization'];
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isLoggingIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);